<script setup>
const attrs = useAttrs();

const cls = computed(() => attrs.class ?? 'w-3 lg:w-4 h-auto');
</script>

<template>
    <svg
        :class="cls"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        data-v-b639b5f3=""
    >
        <path
            d="M13.1875 6.4375H7.5625V0.8125C7.5625 0.663316 7.50324 0.520242 7.39775 0.414753C7.29226 0.309263 7.14918 0.25 7 0.25C6.85082 0.25 6.70774 0.309263 6.60225 0.414753C6.49676 0.520242 6.4375 0.663316 6.4375 0.8125V6.4375H0.8125C0.663316 6.4375 0.520242 6.49676 0.414753 6.60225C0.309263 6.70774 0.25 6.85082 0.25 7C0.25 7.14918 0.309263 7.29226 0.414753 7.39775C0.520242 7.50324 0.663316 7.5625 0.8125 7.5625H6.4375V13.1875C6.4375 13.3367 6.49676 13.4798 6.60225 13.5852C6.70774 13.6907 6.85082 13.75 7 13.75C7.14918 13.75 7.29226 13.6907 7.39775 13.5852C7.50324 13.4798 7.5625 13.3367 7.5625 13.1875V7.5625H13.1875C13.3367 7.5625 13.4798 7.50324 13.5852 7.39775C13.6907 7.29226 13.75 7.14918 13.75 7C13.75 6.85082 13.6907 6.70774 13.5852 6.60225C13.4798 6.49676 13.3367 6.4375 13.1875 6.4375Z"
            data-v-b639b5f3=""
        />
    </svg>
</template>
